import React from "react"
import { Helmet } from "react-helmet"
import Header from "../components/Header"
import Footer from "../components/Footer"

const Links = () => {
  return (
    <div className="wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="author" content="Alp Kahvecioglu"></meta>
        <meta name="description" content="useful links for enpassant"></meta>
        <title>alp kahvecioglu -- links</title>
        <link rel="canonical" href="https://enpassant.me/links" />
        <html lang="en" />
      </Helmet>
      <Header />
      <h1>Here are some places where you can find me:</h1>
      <ul className="links">
        <li>
          <a href="https://github.com/dyslexicat">Github</a>
        </li>
        <li>
          <a href="https://www.hackthebox.eu/home/users/profile/109939">
            Hack the Box
          </a>
        </li>
        <li>
          <a href="https://open.spotify.com/user/dyslexicat?si=yG-Y5LBDTIaRqh37IVEDtQ">
            Spotify
          </a>
        </li>
        <li>
          <a href="https://steamcommunity.com/id/dyslexicat/">Steam</a>
        </li>
        <li>
          <a href="https://twitter.com/dyslexicat">Twitter</a>
        </li>
        <li>
          <a href="https://www.twitch.tv/dyslexicat">Twitch</a>
        </li>

        <li>
          <a href="https://www.instagram.com/alpkahve/">Instagram</a>
        </li>
      </ul>
      <Footer />
    </div>
  )
}

export default Links
